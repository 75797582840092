import i18n from 'i18next';
import type { InitOptions } from 'i18next';
import { initReactI18next } from 'react-i18next';
//import languageDetector  from 'i18next-browser-languagedetector';
import { resources } from './resouces';

const initConfig: InitOptions = {
  debug: process.env.NODE_ENV === 'development',
  lng: 'thai',
  resources,
  keySeparator: false, // we do not use keys in form messages.welcome
  returnNull: false,
  interpolation: {
    escapeValue: false, // react already safes from xss
  },
};

i18n
  //.use(languageDetector)
  .use(initReactI18next) // passes i18n down to react-i18next
  .init(initConfig);

export default i18n;
