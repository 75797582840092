import React from 'react';
import styles from './style.module.scss';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

const Modal = ({ modalIsOpen, handleModal, modalData }: any) => {
  const { t } = useTranslation();

  const rendDetailRes = () => {
    const data = modalData.detail.responsibility;

    if (!data || data?.length === 0) return null;
    return (
      <>
        <div className={styles.modalContentTitle}>
          {t('Jobresponsibilities')}
        </div>
        <div>
          <ul className={styles.modalList}>
            {data.map((v: any, i: any) => {
              return <li key={i}>{v}</li>;
            })}
          </ul>
        </div>
      </>
    );
  };

  const rendDetailReq = () => {
    const data = modalData.detail.requirement;

    if (!data || data?.length === 0) return null;
    return (
      <>
        <div className={styles.modalContentTitle}>{t('jobrequirements')}</div>

        <div>
          <ul className={styles.modalList}>
            {data.map((v: any, i: any) => {
              return <li key={i}>{v}</li>;
            })}
          </ul>
        </div>
      </>
    );
  };

  return (
    <>
      {modalIsOpen ? (
        <div className={styles.modalContainer}>
          <div className={styles.modalWrapper}>
            <div className={styles.modalHead}>
              <div className={styles.modalHeadTitle}>{modalData.name}</div>

              <div>
                <div onClick={handleModal} className={styles.modalCloseBtn}>
                  <img
                    className={styles.modalCloseBtnImg}
                    src={require(`../../../assets/web/job/closeBtnImg.webp`)}
                  />
                </div>
              </div>

              <div className={styles.modalTopContent}>
                <div className={styles.modalTopSubCont}>
                  <div className={styles.modalTopContentItem}>
                    <span
                      className={classNames(styles.salaryTitle, styles.span)}
                    >
                      {modalData.detail.salary}
                    </span>
                  </div>
                  <div className={styles.modalTopContentItem}>
                    {' '}
                    {t('recruits')}
                    <span className={styles.span}>
                      {'  '} {modalData.headCount}
                    </span>
                    {t('people')}
                  </div>
                </div>
                <div className={styles.modalTopSubCont}>
                  <div className={styles.modalTopContentItem}>
                    <span className={classNames(styles.dateTitle, styles.span)}>
                      {' '}
                      {t('releasetime')}{' '}
                      <span
                        className={classNames(
                          styles.dateTitleTime,
                          styles.span,
                        )}
                      >
                        {modalData.time}
                      </span>
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div className={styles.modalContent}>
              <div className={styles.modalRequirement}>
                {rendDetailRes()}
                {rendDetailReq()}
              </div>
            </div>
          </div>
        </div>
      ) : null}
    </>
  );
};

export default Modal;
