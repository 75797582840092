/**
 * @param  {string} ...className
 * 解构react的className数组
 */
export function reactClassNameJoin(...className: any) {
  return className.join(' ');
}

/**
 * 判断是否是 Safari 浏览器
 */
export const isSafariBrowser =
  /Safari/.test(navigator.userAgent) && !/Chrome/.test(navigator.userAgent);
