import { FC } from 'react';
import styles from './style.module.scss';
import { useTranslation } from 'react-i18next';

interface Props {
  slideTo: (index: number) => void;
}

const Home: FC<Props> = ({ slideTo }) => {
  const { t, i18n } = useTranslation();

  const onClickAbout = () => {
    slideTo(1);
  };

  const getHomeTitleCont = () => {
    switch (i18n.language) {
      case 'zh':
        return (
          <>
            <div className={styles.homeTitleCont}>
              <img
                className={styles.homeTitle}
                src={require('@/assets/web/home/home_titile_Chinese@2x.webp')}
              />
              <p className={styles.homeSubtitle}>YOUR SUCCESS, OUR DREAM</p>
            </div>
          </>
        );
      case 'en':
        return (
          <>
            <div className={styles.homeTitleCont}>
              <img
                className={styles.homeTitleEN}
                src={require('@/assets/web/home/home_titile_EN@2x.webp')}
              />
            </div>
          </>
        );
      case 'thai':
        return (
          <>
            <div className={styles.homeTitleCont}>
              <img
                className={styles.homeTitleEN}
                src={require('@/assets/web/home/home_titile_TH@2x.webp')}
              />
            </div>
          </>
        );
    }
  };

  return (
    <main className={styles.home}>
      <div className={styles.homeContainer}>
        <div className={styles.contentContainer}>
          <div className={styles.contentContainerLeft}>
            {getHomeTitleCont()}
            <button
              className={styles.contentContainerLeftButton}
              onClick={onClickAbout}
            >
              {t('AboutUs')}
            </button>
          </div>

          <div className={styles.contentContainerRight}>
            <img
              src={require('@/assets/web/home/home_img@2x.webp')}
              alt="home image"
              className={styles.contentContainerRightImage}
            />
          </div>
        </div>
      </div>
    </main>
  );
};

export default Home;
