import React from 'react';
import styles from './style.module.scss';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

const Home = () => {
  const { t, i18n } = useTranslation();

  const renderHomeTop = () => {
    switch (i18n.language) {
      case 'en':
        return (
          <>
            <img
              src={require('../../../assets/mobile/home/home_titleEn@2x.webp')}
              alt=""
              className={styles.homeTitleEn}
            />
            <img
              src={require('../../../assets/mobile/home/home_subtitleEn@2x.webp')}
              alt=""
              className={styles.homeSubtitleEn}
            />
          </>
        );
      case 'zh':
        return (
          <>
            <img
              src={require('../../../assets/mobile/home/home_title@2x.webp')}
              alt=""
              className={styles.homeTitle}
            />
            <p>YOUR SUCCESS，OUR DREAM</p>
            <img
              src={require('../../../assets/mobile/home/home_subtitle@2x.webp')}
              alt=""
              className={styles.homeSubtitle}
            />
          </>
        );
      case 'thai':
        return (
          <>
            <img
              src={require('../../../assets/mobile/home/home_titleThai@2x.webp')}
              alt=""
              className={styles.homeTitleThai}
            />
            <img
              src={require('../../../assets/mobile/home/home_subtitleThai@2x.webp')}
              alt=""
              className={styles.homeSubtitleThai}
            />
          </>
        );
    }
  };

  const renderHomeBottom = () => {
    switch (i18n.language) {
      case 'en':
        return (
          <>
            <img
              src={require('../../../assets/mobile/home/common_bg_image@2x.webp')}
              alt=""
              className={styles.commonImageEn}
            />

            <div className={styles.homeBottomEn}>
              <img
                src={require('../../../assets/mobile/home/home_img@2x.webp')}
                alt=""
                className={styles.homeImage}
              />
            </div>
          </>
        );
      case 'zh':
        return (
          <>
            <img
              src={require('../../../assets/mobile/home/common_bg_image@2x.webp')}
              alt=""
              className={styles.commonImage}
            />

            <div className={styles.homeBottom}>
              <img
                src={require('../../../assets/mobile/home/home_img@2x.webp')}
                alt=""
                className={styles.homeImage}
              />
            </div>
          </>
        );
      case 'thai':
        return (
          <>
            <img
              src={require('../../../assets/mobile/home/common_bg_image@2x.webp')}
              alt=""
              className={styles.commonImageThai}
            />

            <div className={styles.homeBottomThai}>
              <img
                src={require('../../../assets/mobile/home/home_img@2x.webp')}
                alt=""
                className={styles.homeImage}
              />
            </div>
          </>
        );
    }
  };

  return (
    <main className={classNames(styles.homeMobile, 'SideMenuHome')}>
      <div className={styles.homeTop}>{renderHomeTop()}</div>

      {renderHomeBottom()}
    </main>
  );
};

export default Home;
