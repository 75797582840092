import { FC } from 'react';
import LanguageSelector from '../utils/LanguageSelector';
import styles from './style.module.scss';
import { useTranslation } from 'react-i18next';

interface HeaderProps {
  onOpen: () => void;
}

const Header: FC<HeaderProps> = ({ onOpen: onToggle }) => {
  const { i18n } = useTranslation();
  const onToggleSideNav = () => {
    onToggle();
  };

  return (
    <header className={styles.headerMobile}>
      <nav className={styles.headerMenuNav} onClick={onToggleSideNav} />
      <img
        src={require(
          `../../../assets/mobile/header/logo/${i18n.language}.webp`,
        )}
        alt="logo of the company"
        className={styles.headerLogo}
      />
      <LanguageSelector />
    </header>
  );
};

export default Header;
