import contact_us_telegram_ic from '@assets/web/contact/web_contact_us_telegram_ic@2x.webp';
import contact_us_email_ic from '@assets/web/contact/web_contact_us_email_ic@2x.webp';
import contact_us_longsage_ic from '@assets/web/contact/web_contact_us_longsage_ic@2x.webp';
import contact_us_ic from '@assets/web/contact/web_contact_us_ic@2x.webp';

// 联系信息
export const CONTACTINFO = {
  telegram: '@HR_Recruit',
  linked: 'Apex Leap',
  email: 'Hr@apexleapthailand.com',
  skype: '1563b6d012bff430',
};

// web页联系信息
export const WEBCONTACTINFO = [
  {
    img: {
      src: contact_us_telegram_ic,
      width: '32.16px',
      height: '30.59px',
    },
    info: CONTACTINFO.telegram,
  },
  {
    img: {
      src: contact_us_email_ic,
      width: '34.5px',
      height: '24px',
    },
    info: CONTACTINFO.email,
  },
  {
    img: {
      src: contact_us_longsage_ic,
      width: '31.91px',
      height: '36.66px',
    },
    info: CONTACTINFO.linked,
  },
  {
    img: {
      src: contact_us_ic,
      width: '34px',
      height: '34px',
    },
    info: CONTACTINFO.skype,
  },
];
