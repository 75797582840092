import React, { useEffect, useState } from 'react';
import styles from './search.module.scss';
import {
  JOBLIST,
  ENJOBLIST,
  THAIJOBLIST,
} from 'components/web/opportunities/data';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import emptyImg from '../../../assets/mobile/opportunities/emptyoppImg.webp';
import MobileModal from './MobileModal';
import { ReactComponent as ReactLogo } from '../../../assets/web/job/opportunities_search_ic.svg';
import { ReactComponent as SvgClose } from '../../../assets/mobile/opportunities/Vector.svg';

interface Position {
  id: number;
  name: string;
  headCount: number;
  category: string;
  time: string;
  detail: {
    responsibility: [];
    requirement: [];
    salary: string;
  };
}

const Search = () => {
  const { t, i18n } = useTranslation();
  const [positions, setPositions]: any[] = useState([]);
  const [filteredData, setFilteredData]: any[] = useState([]);
  const [modalData, setModalData] = useState({});
  const [currentPage, setCurrentPage] = useState(1);
  const [postsPerPage] = useState(8);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [isCloseButton, setIsCloseButton] = useState(false);
  const [pagination, setPagination] = useState({
    totalPages: 0,
    page: 1,
  });
  const [showRecommended, setShowRecommended] = useState(true);
  const [keyword, setKeyword] = useState('');
  const [visible, setVisible] = useState(4);
  const [status, setStatus] = useState('');
  const indexOfLastPost = currentPage * postsPerPage;
  const indexOfFirstPost = indexOfLastPost - postsPerPage;
  const currentPosts = filteredData.slice(indexOfFirstPost, indexOfLastPost);

  // const paginate   = (pageNumber:any)  => setCurrentPage(pageNumber);
  const setModalIsOpenToTrue = () => {
    setModalIsOpen(true);
  };
  const setModalIsOpenToFalse = () => {
    setModalIsOpen(false);
  };

  const showMoreItems = () => {
    let temp = visible;

    setPagination((prevState) => ({
      ...prevState,
      page: prevState.page + 1,
    }));
    setVisible(temp + 4);
  };

  useEffect(() => {
    if (i18n.language === 'en') {
      setFilteredData(ENJOBLIST);
      setPositions(ENJOBLIST);
    }

    if (i18n.language === 'thai') {
      setFilteredData(THAIJOBLIST);
      setPositions(THAIJOBLIST);
    }
    if (i18n.language === 'zh') {
      setFilteredData(JOBLIST);
      setPositions(JOBLIST);
    }
  }, [i18n.language]);

  useEffect(() => {
    setPagination((prevState) => ({
      ...prevState,
      totalPages: Math.ceil(filteredData.length / 4),
    }));
  }, [filteredData]);

  // 处理搜索输入
  const handleSearch = (kw: string) => {
    setKeyword(kw);
    if (kw !== '') {
      setIsCloseButton(true);
      const filteredData = positions.filter((item: Position) =>
        item.name.toLowerCase().includes(kw.toLowerCase()),
      );

      const isEmpty = filteredData.length;
      setShowRecommended(isEmpty === 0);
      setStatus(isEmpty === 0 ? 'nomore' : '');

      setFilteredData(filteredData);
    }
  };

  const resetItems = () => {
    setKeyword('');
    setIsCloseButton(false);
    setShowRecommended(true);
    setVisible(4);
    setStatus('');
  };

  const Empty = () => {
    return (
      <div className={styles.oppEmptyImgContainer}>
        <img src={emptyImg} className={styles.emptyImg} />

        <div className={styles.emptyOppText}>{t('emptyOppText')} </div>
      </div>
    );
  };

  return (
    <div className={classNames(styles.oppMbContainer)}>
      <div className={classNames(styles.oppMbSearchContainer)}>
        <button type="submit" className={styles.searchButtonMb}>
          <ReactLogo />
        </button>

        <>
          {isCloseButton ? (
            <div className={styles.oppMbcloseBtn} onClick={resetItems}>
              <SvgClose />
            </div>
          ) : null}
          <input
            onFocus={() => {
              setShowRecommended(true);
            }}
            type="text"
            value={keyword}
            onChange={(e) => handleSearch(e.target.value)}
            className={classNames(styles.searchTermMb)}
            placeholder={t('whatAreYouLookingFor') ?? ''}
          />
        </>
      </div>
      {showRecommended ? (
        <div className={styles.recomSection}>
          <div className={styles.recomTitle}> {t('recommendedSearch')} </div>
          <div className={styles.recomItemContainer}>
            <div
              className={styles.recomItem}
              onClick={() => {
                handleSearch(t('SeniorJAVA') ?? '');
              }}
            >
              {t('SeniorJAVA')}
            </div>

            <div
              className={styles.recomItem}
              onClick={() => {
                handleSearch(t('SeniorUI') ?? '');
              }}
            >
              {t('SeniorUI')}
            </div>
            <div
              className={styles.recomItem}
              onClick={() => {
                handleSearch(t('SeniorUEDesigner') ?? '');
              }}
            >
              {t('SeniorUEDesigner')}
            </div>
            <div
              className={styles.recomItem}
              onClick={() => {
                handleSearch(t('BrandGraphicDesigner') ?? '');
              }}
            >
              {t('BrandGraphicDesigner')}
            </div>
          </div>
        </div>
      ) : null}
      <div className={styles.oppMbCardContainer}>
        {filteredData.length && !showRecommended ? (
          filteredData.slice(0, visible).map((post: Position) => (
            <div
              className={styles.oppMbCard}
              onClick={() => {
                setModalData(post);
                setModalIsOpenToTrue();
              }}
              key={post.id}
            >
              <div className={styles.oppMbCardHeader}>
                <div className={styles.oppMbCardName}>{post.name}</div>

                <div className={styles.cardMbPostTime}> {post.time}</div>
              </div>

              <div className={styles.oppMbCardFooter}>
                <div className={styles.oppMbCardSalary}>
                  {post.detail.salary}
                </div>
                <div className={styles.oppMbCardHeadCount}>
                  {t('recruitment')}{' '}
                  <span className={styles.headCount}> {post.headCount}</span>
                  <> {i18n.language === 'zh' ? t('people') : null}</>
                </div>
              </div>
            </div>
          ))
        ) : (
          <>{status === 'nomore' ? <Empty /> : null}</>
        )}
        <MobileModal
          modalIsOpen={modalIsOpen}
          handleModal={setModalIsOpenToFalse}
          modalData={modalData}
        />
        <div className={styles.oppBtnContainer}>
          {pagination.page < pagination.totalPages ? (
            <button onClick={showMoreItems} className={styles.oppMoreBtn}>
              {t('more')}
            </button>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default Search;
