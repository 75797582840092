import contact_us_telegram_ic from '@assets/mobile/contact/contact_us_telegram_ic@2x.webp';
import contact_us_email_ic from '@assets/mobile/contact/contact_us_email_ic@2x.webp';
import contact_us_longsage_ic from '@assets/mobile/contact/contact_us_longsage_ic@2x.webp';
import contact_us_ic from '@assets/mobile/contact/contact_us_ic@2x.webp';

export const CONTACTINFO = {
  telegram: '@HR_Recruit',
  linked: 'Apex Leap',
  email: 'Hr@apexleapthailand.com',
  skype: '1563b6d012bff430',
};

export const CONTACT_LISTS = [
  {
    img: {
      src: contact_us_telegram_ic,
      width: '38px',
      height: '38px',
    },
    info: CONTACTINFO.telegram,
  },
  {
    img: {
      src: contact_us_email_ic,
      width: '38px',
      height: '38px',
    },
    info: CONTACTINFO.email,
  },
  {
    img: {
      src: contact_us_longsage_ic,
      width: '38px',
      height: '38px',
    },
    info: CONTACTINFO.linked,
  },
  {
    img: {
      src: contact_us_ic,
      width: '38px',
      height: '38px',
    },
    info: CONTACTINFO.skype,
  },
];
