import React, { FC } from 'react';
import styles from './style.module.scss';
import { CONTACT_LISTS } from './data';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

interface Props {}

const Contact: FC<Props> = () => {
  const { t, i18n } = useTranslation();

  const renderTitle = () => {
    switch (i18n.language) {
      case 'en':
        return (
          <>
            <img
              className={styles.contactTitleEN}
              src={require('../../../assets/mobile/contact/contact_us_title_EN@2x.webp')}
            />
          </>
        );
      case 'zh':
        return (
          <>
            <img
              className={styles.contactTitle}
              src={require('../../../assets/mobile/contact/contact_us_title_Chinese@2x.webp')}
            />
            <div className={styles.contactSubtitle}>contact us</div>
          </>
        );
      case 'thai':
        return (
          <>
            <img
              className={styles.contactTitleThai}
              src={require('../../../assets/mobile/contact/contact_us_title_thai@2x.webp')}
            />
          </>
        );
    }
  };

  return (
    <div className={classNames(styles.contactWrapperMobile, 'SideMenuContact')}>
      <div className={styles.contactCont}>
        <div className={styles.titleCont}>{renderTitle()}</div>
        <div className={styles.contactSubCont}>
          <div className={styles.itemCont}>
            {CONTACT_LISTS.map((item, index) => {
              return (
                <div className={styles.contactItem} key={index}>
                  <img
                    src={item.img.src}
                    width={item.img.width}
                    height={item.img.height}
                    className={classNames(styles.contactImg)}
                  />
                  <p className={styles.itemInfo}>{item.info}</p>
                </div>
              );
            })}
          </div>
        </div>
      </div>
      <img
        src={require('../../../assets/mobile/contact/contact us_bg_img@2x.webp')}
        alt=""
        className={styles.contactBackgroundImage}
      />
    </div>
  );
};

export default Contact;
