import styles from './style.module.scss';
import { useTranslation } from 'react-i18next';

const Footer = () => {
  const { t } = useTranslation();
  return (
    <div className={styles.contactFooter}>
      <p className={styles.p}>{t('companyYear')}</p>
      <p className={styles.p}>{t('ContactHr')}</p>
    </div>
  );
};

export default Footer;
