import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import type { MenuProps } from 'antd';
import { Dropdown, Space } from 'antd';
import type { MenuInfo } from 'rc-menu/lib/interface';
import classNames from 'classnames';
import { ReactComponent as SvgUpArrow } from '@/assets/mobile/header/up-arrow.svg';
import { ReactComponent as SvgDownArrow } from '@/assets/mobile/header/down-arrow.svg';
import styles from './LanguageSelector.module.scss';

const items: MenuProps['items'] = [
  {
    label: (
      <div className={styles.languageItem}>
        <img
          src={require(`@/assets/mobile/header/flags/zh.webp`)}
          alt="中文"
          className={styles.flagIcon}
        />
        中文
      </div>
    ),
    key: 'zh',
    title: '中文',
  },
  {
    label: (
      <div className={styles.languageItem}>
        <img
          src={require(`@/assets/mobile/header/flags/en.webp`)}
          alt="EN"
          className={styles.flagIcon}
        />
        EN
      </div>
    ),
    key: 'en',

    title: 'EN',
  },
  {
    label: (
      <div className={styles.languageItem}>
        <img
          src={require(`@/assets/mobile/header/flags/thai.webp`)}
          alt="ภาษาไทย"
          className={styles.flagIcon}
        />
        ภาษาไทย
      </div>
    ),
    key: 'thai',
    title: 'ภาษาไทย',
  },
];

const LanguageSelector: React.FC = () => {
  const { i18n } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);

  // 获取当前语言的名称
  // @ts-ignore
  const currentLang = items.find((item) => item.key === i18n.language)?.title;

  const onClick = ({ key }: MenuInfo): void => {
    i18n.changeLanguage(key);
  };

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  return (
    <Dropdown
      className={styles.dropdownContainer}
      menu={{ items, onClick }}
      onOpenChange={toggleDropdown}
      placement="bottomRight"
      arrow
    >
      <a onClick={(e) => e.preventDefault()}>
        <Space>
          <div
            className={classNames(styles.currentLang, {
              [styles.en]: i18n.language === 'en',
              [styles.thai]: i18n.language === 'thai',
            })}
          >
            {currentLang}
          </div>
          {isOpen ? (
            <>
              <SvgUpArrow className={styles.dropdownArrow} />
            </>
          ) : (
            <>
              <SvgDownArrow className={styles.dropdownArrow} />
            </>
          )}
        </Space>
      </a>
    </Dropdown>
  );
};

export default LanguageSelector;
