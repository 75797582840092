import React, { FC } from 'react';
import styles from './style.module.scss';
import { WEBCONTACTINFO } from 'assets/web/config';
import ContactLeftImage from '@assets/web/contact/contact-common_bg_image.webp';
import { useTranslation } from 'react-i18next';

interface Props {}

const Contact: FC<Props> = () => {
  const { t, i18n } = useTranslation();

  const renderTitle = () => {
    switch (i18n.language) {
      case 'en':
        return (
          <>
            <img
              className={styles.contactTitleEN}
              src={require('../../../assets/web/contact/contact_us_title_EN@2x.webp')}
            />
          </>
        );
      case 'zh':
        return (
          <>
            <img
              className={styles.contactTitle}
              src={require('../../../assets/web/contact/contact_us_title_Chinese@2x.webp')}
            />
            <div className={styles.contactSubtitle}>contact us</div>
          </>
        );
      case 'thai':
        return (
          <>
            <img
              className={styles.contactTitleTHAI}
              src={require('../../../assets/web/contact/contact_us_title_Thai@2x.webp')}
            />
          </>
        );
    }
  };

  return (
    <div className={styles.contactWrapper}>
      <div className={styles.contactCont}>
        <div className={styles.contactLeft}>
          <div className={styles.titleCont}>{renderTitle()}</div>
          <img src={ContactLeftImage} className={styles.contactLeftImage} />
        </div>
        <div className={styles.contactRight}>
          <img
            className={styles.contactBgItem1}
            src={require('@assets/web/contact/contact_us_img2@2x.webp')}
          />
          <div className={styles.contactSubCont}>
            <img
              className={styles.contactBgItem2}
              src={require('@assets/web/contact/contact_us_img@2x.webp')}
            />

            <div className={styles.itemCont}>
              {WEBCONTACTINFO.map((item, index) => {
                return (
                  <div className={styles.contactItem} key={index}>
                    <img
                      src={item.img.src}
                      width={item.img.width}
                      height={item.img.height}
                      className={styles.contactImg}
                    />
                    <p className={styles.itemInfo}>{item.info}</p>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contact;
