import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import type { MenuProps } from 'antd';
import { Dropdown, Space } from 'antd';
import type { MenuInfo } from 'rc-menu/lib/interface';
import { ReactComponent as SvgUpArrow } from '@/assets/web/header/up-arrow.svg';
import { ReactComponent as SvgDownArrow } from '@/assets/web/header/down-arrow.svg';
import styles from './LanguageSelector.module.scss';

const items: MenuProps['items'] = [
  {
    label: (
      <div className={styles.languageItem}>
        <img
          src={require(`@/assets/web/header/flags/zh.webp`)}
          alt="简体中文"
          className={styles.flagIcon}
        />
        简体中文
      </div>
    ),
    key: 'zh',
  },
  {
    label: (
      <div className={styles.languageItem}>
        <img
          src={require(`@/assets/web/header/flags/en.webp`)}
          alt="English"
          className={styles.flagIcon}
        />
        English
      </div>
    ),
    key: 'en',
  },
  {
    label: (
      <div className={styles.languageItem}>
        <img
          src={require(`@/assets/web/header/flags/thai.webp`)}
          alt="ภาษาไทย"
          className={styles.flagIcon}
        />
        ภาษาไทย
      </div>
    ),
    key: 'thai',
  },
];

const LanguageSelector: React.FC = () => {
  const { i18n } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);

  // 获取当前语言的名称
  // @ts-ignore
  const currentLang = items.find((item) => item.key === i18n.language)?.label;

  const onClick = ({ key }: MenuInfo): void => {
    i18n.changeLanguage(key);
  };

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  return (
    <Dropdown
      menu={{ items, onClick }}
      onOpenChange={toggleDropdown}
      placement="bottom"
      arrow={{ pointAtCenter: true }}
    >
      <a
        onClick={(e) => e.preventDefault()}
        className={styles.dropdownContainer}
      >
        <Space>
          <div className={styles.currentLang}>{currentLang}</div>
          {isOpen ? (
            <>
              <SvgUpArrow className={styles.dropdownArrow} />
            </>
          ) : (
            <>
              <SvgDownArrow className={styles.dropdownArrow} />
            </>
          )}
        </Space>
      </a>
    </Dropdown>
  );
};

export default LanguageSelector;
