import { useTranslation } from 'react-i18next';
import styles from './style.module.scss';
import classNames from 'classnames';

const About = () => {
  const { t, i18n } = useTranslation();

  const renderTitle = () => {
    switch (i18n.language) {
      case 'en':
        return (
          <>
            <img
              className={styles.pageTitleEn}
              src={require('../../../assets/mobile/about/title_English@2x.webp')}
              alt="english title"
            />
          </>
        );
      case 'zh':
        return (
          <>
            <div className={styles.chTitleContainer}>
              <img
                className={styles.pageTitleCh}
                src={require('../../../assets/mobile/about/title_Chinese@2x.webp')}
                alt="chinese title"
              />
              <p className={styles.p}>introduction</p>
            </div>
          </>
        );
      case 'thai':
        return (
          <>
            <img
              className={styles.pageTitleThai}
              src={require('../../../assets/mobile/about/title_Thai@2x.webp')}
              alt="thai title"
            />
          </>
        );
    }
  };

  return (
    <div className={classNames(styles.aboutContainer, 'SideMenuAbout')}>
      <div className={styles.introductionHeader}>
        <div className={styles.titleContainer}>{renderTitle()}</div>
        <img
          className={styles.robot}
          src={require('../../../assets/mobile/about/robot.webp')}
          alt="robot"
        />
      </div>
      <div className={styles.aboutDescWrapper}>
        <div className={styles.labelBg}>
          <p className={styles.aboutTitle}>{t('companyIntroduction')}</p>
        </div>
        <p className={styles.aboutDesc}>{t('companyDetail')}</p>
        <img
          className={styles.globe}
          src={require('../../../assets/mobile/about/globe-bg.webp')}
          alt="globr"
        />
      </div>

      <div className={styles.cardsContainer}>
        {/* First Card */}
        <div className={styles.cardItem}>
          <div className={styles.cardCol}>
            <div className={styles.cardLeft}>
              <img
                className={styles.strategy}
                src={require('../../../assets/mobile/about/strategy@2x.webp')}
                alt="strategy image"
              />
            </div>
            <div className={styles.cardRight}>
              <div className={styles.cardDetailWrapper}>
                <div className={styles.cardDetailTitle}>{t('Strategy')}</div>
                <div className={styles.cardDetailDesc}>
                  {t('StrategyDetail')}
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Second Card*/}
        <div className={styles.cardItem}>
          <div className={styles.cardCol}>
            <div className={styles.cardLeft}>
              <img
                className={styles.vision}
                src={require('../../../assets/mobile/about/vision@2x.webp')}
                alt="vision image"
              />
            </div>
            <div className={styles.cardRight}>
              <div className={styles.cardDetailWrapper}>
                <div className={styles.cardDetailTitle}>{t('Vision')}</div>
                <div className={styles.cardDetailDesc}>{t('VisionDetail')}</div>
              </div>
            </div>
          </div>
        </div>
        {/* Third Card */}
        <div className={styles.cardItem}>
          <div className={styles.cardCol}>
            <div className={styles.cardLeft}>
              <img
                className={styles.mission}
                src={require('../../../assets/mobile/about/mission@2x.webp')}
                alt="mission image"
              />
            </div>
            <div className={styles.cardRight}>
              <div className={styles.cardDetailWrapper}>
                <div className={styles.cardDetailTitle}>{t('Mission')}</div>
                <div className={styles.cardDetailDesc}>
                  {t('MissionDetail')}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default About;
