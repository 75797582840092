import { SideNavItem } from './declarations';
import home from 'assets/mobile/sideMenu/home@2x.webp';
import companyOverview from 'assets/mobile/sideMenu/company-overview@2x.webp';
import benefits from 'assets/mobile/sideMenu/benefits@2x.webp';
import jobOpportunities from 'assets/mobile/sideMenu/job-opportunities@2x.webp';
import contactUs from 'assets/mobile/sideMenu/contact-us@2x.webp';
import faq from 'assets/mobile/sideMenu/faq@2x.webp';
import { Translation } from 'react-i18next';

export const MobileSideNavItems: SideNavItem[] = [
  {
    index: 0,
    title: <Translation>{(t) => <>{t('longsagePage')}</>}</Translation>,
    image: home,
  },
  {
    index: 1,
    title: <Translation>{(t) => <>{t('companyProfile')}</>}</Translation>,
    image: companyOverview,
  },
  {
    index: 2,
    title: <Translation>{(t) => <>{t('benefitsButton')}</>}</Translation>,
    image: benefits,
  },
  {
    index: 3,
    title: <Translation>{(t) => <>{t('opportunities')}</>}</Translation>,
    image: jobOpportunities,
  },
  {
    index: 4,
    title: <Translation>{(t) => <>{t('contactUs')}</>}</Translation>,
    image: contactUs,
  },
  // {
  //   index: 5,
  //   title: <Translation>{(t) => <>{t('faqs')}</>}</Translation>,
  //   image: faq,
  // },
];
