import styles from './style.module.scss';
import { useTranslation } from 'react-i18next';

const Footer = () => {
  const { t } = useTranslation();
  return (
    <footer className={styles.footer}>
      <p className={styles.p}>{t('companyYear')}</p>
      <p className={styles.p}>{t('ContactHr')}</p>
    </footer>
  );
};

export default Footer;
