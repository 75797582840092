import React, { useEffect, useState } from 'react';
import styles from './style.module.scss';
import Modal from './Modal';
import { JOBLIST, ENJOBLIST, THAIJOBLIST } from './data';
import { useTranslation } from 'react-i18next';

import emptyImg from '../../../assets/web/job/emptyImg.png';
import classNames from 'classnames';

const Opportunities = () => {
  const { t, i18n } = useTranslation();
  const [dataArr, setDataArr]: any[] = ([] = useState([]));
  const [newDataArr, setNewDataArr]: any[] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [postsPerPage] = useState(8);
  const [activeCardId, setActiveCardId] = useState(null);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [modalData, setModalData] = useState({});
  const indexOfLastPost = currentPage * postsPerPage;
  const indexOfFirstPost = indexOfLastPost - postsPerPage;
  const currentPosts = newDataArr.slice(indexOfFirstPost, indexOfLastPost);

  const paginate = (pageNumber: any) => setCurrentPage(pageNumber);
  const setModalIsOpenToTrue = () => {
    setModalIsOpen(true);
  };
  const setModalIsOpenToFalse = () => {
    setModalIsOpen(false);
  };

  const pageNumbers = [];

  for (let i = 1; i <= Math.ceil(newDataArr.length / postsPerPage); i++) {
    pageNumbers.push(i);
  }

  useEffect(() => {
    if (i18n.language === 'en') {
      setNewDataArr(ENJOBLIST);
      setDataArr(ENJOBLIST);
    }
    if (i18n.language === 'zh') {
      setNewDataArr(JOBLIST);
      setDataArr(JOBLIST);
    }

    if (i18n.language === 'thai') {
      setNewDataArr(THAIJOBLIST);
      setDataArr(THAIJOBLIST);
    }
  }, [i18n.language]);

  const searchItems = (searchValue: string) => {
    if (searchValue !== '') {
      const filteredData = dataArr.filter((item: any) => {
        return item.name.toLowerCase().includes(searchValue.toLowerCase());
      });

      setNewDataArr(filteredData);
    } else {
      setNewDataArr(dataArr);
    }
  };

  const renderTitle = () => {
    switch (i18n.language) {
      case 'en':
        return (
          <>
            <img
              className={styles.oppTitleEn}
              src={require(`../../../assets/web/job/opp_title_EN.webp`)}
            />
            <div className={styles.titleExtraEn}>{t('billion')}</div>
          </>
        );
      case 'zh':
        return (
          <>
            <img
              className={styles.oppTitle}
              src={require('../../../assets/web/job/opp_title_Chinese.webp')}
            />
            <div className={styles.titleExtra}> {t('oppSubTitle')}</div>
            <div className={styles.titleExtraBillion}> {t('billion')}</div>
          </>
        );
      case 'thai':
        return (
          <>
            <img
              className={styles.oppTitleThai}
              src={require(`../../../assets/web/job/opp_title_Thai.webp`)}
            />
            <div className={styles.titleExtraThai}> {t('billion')}</div>
          </>
        );
    }
  };

  return (
    <div>
      <div className={styles.oppContainer}>
        <div className={styles.leftContainer}>
          <div className={styles.headerOpp}>
            <div className={styles.title}>{renderTitle()}</div>

            <div className={styles.imgContainer}>
              <img
                className={styles.oppLogoImg}
                src={require('../../../assets/web/job/common_bg_image.webp')}
              />
              <img
                className={styles.oppImg}
                src={require('../../../assets/web/job/opportunities_img@2x.webp')}
              />
            </div>
          </div>
        </div>

        <div className={styles.rightContainer}>
          <div className={styles.searchContainer}>
            <div className={styles.wrap}>
              <div className={styles.search}>
                <button type="submit" className={styles.searchButton}>
                  {t('JobSearch')}:
                </button>

                <div className={styles.searchTerm}>
                  <input
                    type="text"
                    onChange={(e) => searchItems(e.target.value)}
                    className={styles.searchTermInput}
                    placeholder={t('jobtitle') ?? ''}
                  />
                </div>
              </div>
            </div>
          </div>
          <Modal
            modalIsOpen={modalIsOpen}
            handleModal={setModalIsOpenToFalse}
            modalData={modalData}
          />

          <div className={styles.oppCardContainer}>
            <>
              {currentPosts.length > 0 ? (
                <>
                  {currentPosts.map((post: any) => (
                    <div
                      key={post.id}
                      className={classNames(styles.oppCard, {
                        [styles.oppCardActive]: activeCardId === post.id,
                      })}
                      onClick={() => {
                        setModalData(post);
                        setModalIsOpenToTrue();
                      }}
                    >
                      <div className={styles.oppCardHeader}>
                        <div className={styles.cardPostTime}> {post.time}</div>
                        <button
                          className={styles.cardButton}
                          onClick={() => {
                            setModalData(post);
                            setModalIsOpenToTrue();
                          }}
                        >
                          {' '}
                          {t('detail')}
                        </button>
                      </div>

                      <div className={styles.oppCardDetails}>
                        <div className={styles.oppCardName}>{post.name}</div>
                        <div className={styles.oppCardSalary}>
                          {post.detail.salary}
                        </div>
                      </div>
                      <div className={styles.oppCardFooter}>
                        {t('recruitment')}{' '}
                        <span className={styles.headCount}>
                          {' '}
                          {post.headCount}{' '}
                        </span>{' '}
                        {t('people')}
                      </div>
                    </div>
                  ))}

                  {newDataArr.length > 8 ? (
                    <>
                      <div className={styles.oppPagination}>
                        {pageNumbers.map((number) => (
                          <div
                            key={number}
                            onClick={() => paginate(number)}
                            className={classNames({
                              [styles.oppPageItem]: number !== currentPage,
                              [styles.oppPageItemActive]: number == currentPage,
                            })}
                          >
                            <div className={styles.pageLink}>{number}</div>
                          </div>
                        ))}

                        <div
                          className={styles.oppPageItem}
                          onClick={() => {
                            if (currentPage < pageNumbers.length) {
                              paginate(currentPage + 1);
                            }
                          }}
                        >
                          {' '}
                          {t('NextPage')}
                        </div>
                      </div>
                    </>
                  ) : null}
                </>
              ) : (
                <div className={styles.oppEmptyCardContainer}>
                  <img src={emptyImg} className={styles.emptySearchImg} />
                  <div className={styles.emptySearchText}>
                    {t('emptySearchText')}{' '}
                  </div>
                </div>
              )}
            </>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Opportunities;
