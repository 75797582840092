import styles from './style.module.scss';
import { useTranslation } from 'react-i18next';
import { FC } from 'react';
import classNames from 'classnames';

interface Props {}

const Bonus: FC<Props> = () => {
  const { t, i18n } = useTranslation();

  const renderTitle = () => {
    switch (i18n.language) {
      case 'en':
        return (
          <>
            <img
              className={styles.bonusTitleEN}
              src={require('../../../assets/mobile/bonus/salary_title_EN@2x.webp')}
            />
          </>
        );
      case 'zh':
        return (
          <>
            <img
              className={styles.bonusTitle}
              src={require('../../../assets/mobile/bonus/salary_title_Chinese@2x.webp')}
            />
            <div className={styles.bonusSubtitle}>salary / benefits</div>
          </>
        );
      case 'thai':
        return (
          <>
            <img
              className={styles.bonusTitleThai}
              src={require('../../../assets/mobile/bonus/salary_title_thai@2x.webp')}
            />
          </>
        );
    }
  };

  return (
    <div
      className={classNames(styles.bonusWrapperMobile, 'SideMenuBonus')}
      id="bonus"
    >
      <div className={styles.bonusCont}>
        <div className={styles.bonusHeader}>
          <div className={styles.titleCont}>{renderTitle()}</div>
          <div className={styles.bonusDesc}>{t('salaryDetail')}</div>
        </div>
        <div className={styles.itemCont}>
          {/* SALARY */}
          <div className={styles.bonusItem}>
            <div className={styles.bonusIcon} />
            <div className={styles.bonusText}>
              <div className={styles.bonusType}>{t('Salary1')}</div>
              <ul className={styles.ul}>
                <li className={styles.li}>{t('Salary_1')}</li>
                <li className={styles.li}>{t('Salary_2')}</li>
              </ul>
            </div>
          </div>
          {/* LEAVES */}
          <div className={styles.bonusItem}>
            <div className={styles.bonusIcon} />
            <div className={styles.bonusText}>
              <div className={styles.bonusType}>{t('benefits')}</div>
              <ul className={styles.ul}>
                <li className={styles.li}>{t('benefits_1')}</li>
                <li className={styles.li}>{t('benefits_2')}</li>
                <li className={styles.li}>{t('benefits_3')}</li>
              </ul>
            </div>
          </div>
          {/* MEALS */}
          <div className={styles.bonusItem}>
            <div className={styles.bonusIcon} />
            <div className={styles.bonusText}>
              <div className={styles.bonusType}>{t('meals')}</div>
              <ul className={styles.ul}>
                <li className={styles.li}>{t('meals_1')}</li>
              </ul>
            </div>
          </div>
          {/* TRANSPORTATION */}
          <div className={styles.bonusItem}>
            <div className={styles.bonusIcon} />
            <div className={styles.bonusText}>
              <div className={styles.bonusType}>{t('Transportation')}</div>
              <ul className={styles.ul}>
                <li className={styles.li}>{t('Transportation_1')} </li>
              </ul>
            </div>
          </div>
          {/* HOLIDAY */}
          <div className={styles.bonusItem}>
            <div className={styles.bonusIcon} />
            <div className={styles.bonusText}>
              <div className={styles.bonusType}>{t('LeaveEntitlement')}</div>
              <ul className={styles.ul}>
                <li className={styles.li}>{t('LeaveEntitlement_1')} </li>
                <li className={styles.li}>{t('LeaveEntitlement_2')}</li>
                <li className={styles.li}>{t('LeaveEntitlement_3')}</li>
                <li className={styles.li}>{t('LeaveEntitlement_4')}</li>
              </ul>
            </div>
          </div>
          {/* COMPANY ACTIVITIES */}
          <div className={styles.bonusItem}>
            <div className={styles.bonusIcon} />
            <div className={styles.bonusText}>
              <div className={styles.bonusType}>{t('TeamBuilding')}</div>
              <ul className={styles.ul}>
                <li className={styles.li}>{t('TeamBuilding_1')}</li>
                <li className={styles.li}>{t('TeamBuilding_2')}</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Bonus;
