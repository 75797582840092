import { useState } from 'react';
import Swiper, { Mousewheel, Pagination } from 'swiper';
import { SwiperProps } from 'swiper/react';
import SideNav from '../../components/web/sidenav';
import styles from '@/components/web/sidenav/style.module.scss';

export const useSideNavigation = (sections: any[]) => {
  // Clicking a navigation item doesn't re-render the page automatically, hence the state.
  const [activeIndex, setActiveIndex] = useState<number>(0);
  const [swiper, setSwiper] = useState<Swiper>();

  const onClick = (index: number) => {
    setActiveIndex(index);
    swiper?.slideTo(index);
  };

  /**
   * Moves the Swiper to the index
   * @param index The view to slide to
   */
  const slideTo = (index: number) => {
    onClick(index);
  };

  /**
   * Enables/disables the Swiper
   * @param value Whether to disable or enable
   */
  const toggle = (value: boolean) => {
    if (value) {
      swiper?.enable();
    } else {
      swiper?.disable();
    }
  };

  const swiperProperties: SwiperProps = {
    slidesPerView: 1,

    spaceBetween: 0,
    // mousewheel: {
    //   invert:true,
    // },
    mousewheel: true,
    freeMode: true,
    // loop: true,
    loopedSlides: 6,
    // loop:false,
    modules: [Pagination, Mousewheel],
    pagination: {
      type: 'custom',
      clickable: true,
      verticalClass: styles.Container,
    },
    scrollbar: { draggable: true },
    onInit(swiper) {
      setSwiper(swiper);
    },
    onPaginationUpdate(swiper) {
      setActiveIndex(swiper.activeIndex);
    },
    height: window.innerHeight,
    grabCursor: true,
  };

  const SideNavigation = () => (
    <SideNav
      titles={sections}
      activeIndex={activeIndex}
      setActiveIndex={onClick}
    />
  );

  return {
    swiperProperties,
    SideNavigation,
    slideTo,
    toggle,
  };
};
