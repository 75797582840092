import LanguageSelector from '../utils/LanguageSelector';
import { isSafariBrowser } from '../../../utils/helpers';
import styles from './style.module.scss';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

const Header = () => {
  const { i18n } = useTranslation();
  return (
    <header className={styles.header}>
      <div
        className={classNames({
          [styles.headerContainerSafari]: isSafariBrowser,
          [styles.headerContainer]: !isSafariBrowser,
        })}
      >
        <img
          src={require(`@/assets/web/header/logo/${i18n.language}.png`)}
          alt="logo of the company"
          className={styles.headerLogo}
        />
        <LanguageSelector />
      </div>
    </header>
  );
};

export default Header;
