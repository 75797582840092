import React from 'react';
import styles from './style.module.scss';
import { useTranslation } from 'react-i18next';
import { ReactComponent as SvgBack } from '../../../assets/mobile/opportunities/svgBackBtn.svg';
import { ReactComponent as SvgClock } from '../../../assets/mobile/opportunities/clock.svg';
const MobileModal = ({ modalIsOpen, handleModal, modalData }: any) => {
  const { t } = useTranslation();
  const rendDetailRes = () => {
    const data = modalData.detail.responsibility;

    if (!data) return null;
    return (
      <>
        <div className={styles.modalMbContentTitle}>
          {t('Jobresponsibilities')}
        </div>
        <div className={styles.modalMbListContainer}>
          <ul className={styles.modalMbList}>
            {data.map((v: any, i: any) => {
              return <li key={i}>{v}</li>;
            })}
          </ul>
        </div>
      </>
    );
  };

  const rendDetailReq = () => {
    const data = modalData.detail.requirement;

    if (!data) return null;
    return (
      <>
        <div className={styles.modalMbContentTitle}>{t('jobrequirements')}</div>

        <div>
          <ul className={styles.modalMbList}>
            {data.map((v: any, i: any) => {
              return <li key={i}>{v}</li>;
            })}
          </ul>
        </div>
      </>
    );
  };

  return (
    <>
      {modalIsOpen ? (
        <div className={styles.modalMbContainer}>
          <div className={styles.modalMbHead}>
            <div className={styles.modalMbHeadTitle}>
              <div onClick={handleModal} className={styles.modalMbCloseBtn}>
                <SvgBack />
              </div>{' '}
              <div className={styles.modalJobDetails}>{t('jobDetails')}</div>
            </div>
          </div>

          <div className={styles.modalMbTopContent}>
            <div className={styles.modalMbTopContentItem1}>
              <div className={styles.modalMbTopContentName}>
                {modalData.name}
              </div>

              <div className={styles.modalMbTopContentCount}>
                {t('recruitment')}{' '}
                <span className={styles.count}>{modalData.headCount} </span>{' '}
                {t('people')}
              </div>
            </div>
            <div className={styles.modalMbTopContentItem2}>
              <div className={styles.modalMbTopContentSalary}>
                {modalData.detail.salary}
              </div>
              <div className={styles.modalMbTopContentTime}>
                <SvgClock />
                <div className={styles.modalMbTimeSec}>{modalData.time}</div>
              </div>
            </div>
          </div>

          <div className={styles.modalMbContent}>
            <div className={styles.modalMbResponse} />

            <div className={styles.modalMbRequirement}>
              {rendDetailRes()}
              {rendDetailReq()}
              {/* <div className='modalContentTitle'>Job Requirements</div>
  <div>{modalData.detail.requirement}</div> */}
            </div>
          </div>
        </div>
      ) : null}
    </>
  );
};

export default MobileModal;
