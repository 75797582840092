/**
 *404页面
 */
import styles from './style.module.scss';
import { useTranslation } from 'react-i18next';
import errorImg from '../../../../assets/web/maintenance/404.png';

const NotFoundPage = () => {
  const { t } = useTranslation();
  return (
    <div className={styles.notFoundPageWeb}>
      <img src={errorImg} className={styles.errorImg} />

      <div className={styles.errorText}> {t('errorText')}</div>
    </div>
  );
};

export default NotFoundPage;
