import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styles from './style.module.scss';
import {
  JOBLIST,
  ENJOBLIST,
  THAIJOBLIST,
} from 'components/web/opportunities/data';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import oppTitleChinese from '../../../assets/mobile/opportunities/title_Chinese.webp';
import oppTitleEn from '../../../assets/mobile/opportunities/opportunities_title_EN.webp';
import oppTitleThai from '../../../assets/mobile/opportunities/opportunities_title_thai.webp';
import emptyImg from '../../../assets/mobile/opportunities/emptyoppImg.webp';
import MobileModal from './MobileModal';
import { ReactComponent as ReactLogo } from '../../../assets/web/job/opportunities_search_ic.svg';
import { ReactComponent as SvgClose } from '../../../assets/mobile/opportunities/Vector.svg';

const Opportunities = () => {
  const { t, i18n } = useTranslation();
  const [dataArr, setDataArr]: any[] = useState(ENJOBLIST);
  const [newDataArr, setNewDataArr]: any[] = useState(ENJOBLIST);
  const [modalData, setModalData] = useState({});
  const [currentPage, setCurrentPage] = useState(1);
  const [postsPerPage] = useState(8);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [isCloseButton, setIsCloseButton] = useState(false);
  const [pagination, setPagination] = useState({
    totalPages: 0,
    page: 1,
  });
  const [searchInput, setSearchInput] = useState('');
  const [visible, setVisible] = useState(4);
  const navigate = useNavigate();
  const indexOfLastPost = currentPage * postsPerPage;
  const indexOfFirstPost = indexOfLastPost - postsPerPage;
  const currentPosts = newDataArr.slice(indexOfFirstPost, indexOfLastPost);

  // const paginate   = (pageNumber:any)  => setCurrentPage(pageNumber);
  const setModalIsOpenToTrue = () => {
    setModalIsOpen(true);
  };
  const setModalIsOpenToFalse = () => {
    setModalIsOpen(false);
  };

  const showMoreItems = () => {
    let temp = visible;

    setPagination((prevState) => ({
      ...prevState,
      page: prevState.page + 1,
    }));
    setVisible(temp + 4);
  };

  useEffect(() => {
    if (i18n.language === 'en') {
      setNewDataArr(ENJOBLIST);
      setDataArr(ENJOBLIST);
    }

    if (i18n.language === 'thai') {
      setNewDataArr(THAIJOBLIST);
      setDataArr(THAIJOBLIST);
    }
    if (i18n.language === 'zh') {
      setNewDataArr(JOBLIST);
      setDataArr(JOBLIST);
    }
  }, [i18n.language]);

  useEffect(() => {
    setPagination((prevState) => ({
      ...prevState,
      totalPages: Math.ceil(newDataArr.length / 4),
    }));
  }, [newDataArr]);

  const searchItems = (searchValue: string) => {
    setSearchInput(searchValue);
    if (searchValue !== '') {
      setIsCloseButton(true);
      const filteredData = dataArr.filter((item: any) => {
        return item.name.toLowerCase().includes(searchValue.toLowerCase());
      });

      setNewDataArr(filteredData);
    } else {
      searchValue = '';
      setNewDataArr(dataArr);
    }
  };
  const resetItems = () => {
    setSearchInput('');
    setIsCloseButton(false);
    searchItems('');
    setVisible(4);
  };

  const renderTitle = () => {
    switch (i18n.language) {
      case 'en':
        return (
          <>
            <img
              src={oppTitleEn}
              alt="en title"
              className={styles.oppMbTitle}
            />
          </>
        );
      case 'zh':
        return (
          <>
            <img
              src={oppTitleChinese}
              alt="chinese title"
              className={styles.oppMbTitleZh}
            />
            <div className={styles.titleMbExtra}>Opportunities</div>
            <div className={styles.titleMbText}>{t('billion')}</div>
          </>
        );
      case 'thai':
        return (
          <>
            <img
              src={oppTitleThai}
              alt="thai title"
              className={styles.oppMbTitle}
            />
          </>
        );
    }
  };

  return (
    <div className={classNames(styles.oppMbContainer, 'SideMenuOpportunities')}>
      <div className={styles.oppMbHeader}>
        <div className={styles.oppMbTitleText}>{renderTitle()}</div>
      </div>
      <div className={classNames(styles.oppMbSearchContainer)}>
        <button type="submit" className={styles.searchButtonMb}>
          <ReactLogo />
        </button>

        <>
          {isCloseButton ? (
            <div className={styles.oppMbcloseBtn} onClick={resetItems}>
              <SvgClose />
            </div>
          ) : null}
          <input
            onClick={() => {
              navigate('/search');
            }}
            type="text"
            value={searchInput}
            onChange={(e) => searchItems(e.target.value)}
            className={classNames(styles.searchTermMb)}
            placeholder={t('whatAreYouLookingFor') ?? ''}
          />
        </>
      </div>
      <div className={styles.oppMbCardContainer}>
        {newDataArr.length ? (
          newDataArr.slice(0, visible).map((post: any) => (
            <div
              className={styles.oppMbCard}
              onClick={() => {
                setModalData(post);
                setModalIsOpenToTrue();
              }}
              key={post.id}
            >
              <div className={styles.oppMbCardHeader}>
                <div className={styles.oppMbCardName}>{post.name}</div>

                <div className={styles.cardMbPostTime}> {post.time}</div>
              </div>

              <div className={styles.oppMbCardFooter}>
                <div className={styles.oppMbCardSalary}>
                  {post.detail.salary}
                </div>
                <div className={styles.oppMbCardHeadCount}>
                  {t('recruitment')}{' '}
                  <span className={styles.headCount}> {post.headCount}</span>
                  <> {i18n.language === 'zh' ? t('people') : null}</>
                </div>
              </div>
            </div>
          ))
        ) : (
          <div className={styles.oppEmptyImgContainer}>
            <img src={emptyImg} className={styles.emptyImg} />

            <div className={styles.emptyOppText}>{t('emptyOppText')} </div>
          </div>
        )}
        <MobileModal
          modalIsOpen={modalIsOpen}
          handleModal={setModalIsOpenToFalse}
          modalData={modalData}
        />
        <div className={styles.oppBtnContainer}>
          {pagination.page < pagination.totalPages ? (
            <button onClick={showMoreItems} className={styles.oppMoreBtn}>
              {t('more')}
            </button>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default Opportunities;
