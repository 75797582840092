import { useTranslation } from 'react-i18next';
import React, { FC, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { PaginationOptions, Swiper as SwiperClass } from 'swiper/types';
import { Pagination } from 'swiper';
import styles from './style.module.scss';
import 'swiper/css';
import 'swiper/css/pagination';
import strategy_img_hover from '@assets/web/About/strategy_img_hover@2x.webp';
import vision_img_hover from '@assets/web/About/vision_img_hover@2x.webp';
import mission_img_hover from '@assets/web/About/mission_img_hover@2x.webp';
import classNames from 'classnames';

const preImgList = [strategy_img_hover, vision_img_hover, mission_img_hover];

interface BulletProps {
  index: number;
}

interface AboutProps {
  toggleMainSwiper: (value: boolean) => void;
}

const About: FC<AboutProps> = ({ toggleMainSwiper }) => {
  const [activeIndex, setActiveIndex] = useState<number>(0);
  const [swiper, setSwiper] = useState<SwiperClass>();
  const { t, i18n } = useTranslation();
  const pagination: PaginationOptions = {
    type: 'bullets',
    clickable: true,
  };

  const renderTitle = () => {
    switch (i18n.language) {
      case 'en':
        return (
          <>
            <div className={styles.labelBgEn}>
              <p className={styles.aboutTitleEn}>{t('companyIntroduction')}</p>
            </div>
          </>
        );
      case 'zh':
        return (
          <>
            <div className={styles.labelBgZh}>
              <p className={styles.aboutTitleZh}>{t('companyIntroduction')}</p>
            </div>
          </>
        );
      case 'thai':
        return (
          <>
            <div className={styles.labelBgThai}>
              <p className={styles.aboutTitleThai}>
                {t('companyIntroduction')}
              </p>
            </div>
          </>
        );
    }
  };

  const FirstSlide = () => {
    return (
      <div className={styles.whiteBoxAbout}>
        {renderTitle()}
        <p className={styles.aboutDesc}>{t('companyDetail')}</p>
      </div>
    );
  };

  const SecondSlide = () => {
    return (
      <div className={styles.whiteBoxCards}>
        <div className={styles.cards}>
          <div className={styles.strategyAbout} />
          <div className={styles.cardDetail}>
            <div className={styles.cardDetailTitle}>{t('Strategy')}</div>
            <div className={styles.cardDetailDesc}>{t('StrategyDetail')}</div>
          </div>
        </div>

        <div className={styles.cards}>
          <div className={styles.visionAbout} />
          <div className={styles.cardDetail}>
            <div className={styles.cardDetailTitle}>{t('Vision')}</div>
            <div className={styles.cardDetailDesc}>{t('VisionDetail')}</div>
          </div>
        </div>

        <div className={styles.cards}>
          <div className={styles.missionAbout} />
          <div className={styles.cardDetail}>
            <div className={styles.cardDetailTitle}>{t('Mission')}</div>
            <div className={styles.cardDetailDesc}>{t('MissionDetail')}</div>
          </div>
        </div>
        {/*图片预加载*/}
        <div style={{ display: 'none' }}>
          {preImgList.map((item: string, index: number) => {
            return <img src={item} key={index} />;
          })}
        </div>
      </div>
    );
  };

  const Bullet = (props: BulletProps) => {
    const onClick = () => {
      swiper?.slideTo(props.index);
      setActiveIndex(props.index);
    };
    return (
      <div
        className={classNames(styles.bullet, styles.swiperPaginationBullet, {
          [styles.swiperPaginationBulletActive]: props.index === activeIndex,
        })}
        onClick={onClick}
      />
    );
  };

  const renderTitleContainer = () => {
    switch (i18n.language) {
      case 'en':
        return (
          <>
            <img
              className={styles.pageTitleEn}
              src={require('../../../assets/web/About/title_EN@2x.webp')}
              alt="english title"
            />
          </>
        );
      case 'zh':
        return (
          <>
            <div className={styles.chTitleContainer}>
              <img
                className={styles.pageTitleCh}
                src={require('../../../assets/web/About/title_ZH@2x.webp')}
                alt="chinese title"
              />
              <p className={styles.p}>introduction</p>
            </div>
          </>
        );
      case 'thai':
        return (
          <>
            <div className={styles.thaiTitleContainer}>
              <img
                className={styles.pageTitleThai}
                src={require('../../../assets/web/About/title_Thai@2x.webp')}
                alt="thai title"
              />
            </div>
          </>
        );
    }
  };

  return (
    <div className={styles.about}>
      <div className={styles.aboutContent}>
        <div className={styles.titleContainer}>{renderTitleContainer()}</div>
        <div className={styles.rowContainer}>
          <div className={styles.leftContent}>
            <img
              className={styles.logoBg}
              src={require('../../../assets/web/About/common_bg_image@2x.webp')}
              alt="label"
            />
          </div>
          <div className={styles.rightContent}>
            <div className={styles.homeSliderContainer}>
              <img
                className={styles.robot}
                src={require('../../../assets/web/About/robot@2x.webp')}
                alt="robot"
              />
              <div className={styles.sliderWrapper}>
                {/* Slide one */}
                <Swiper
                  pagination={pagination}
                  modules={[Pagination]}
                  onInit={(swiper: SwiperClass) => {
                    setSwiper(swiper);
                  }}
                  onPaginationUpdate={(swiper: SwiperClass) => {
                    setActiveIndex(swiper?.activeIndex);
                  }}
                >
                  <SwiperSlide>
                    {' '}
                    <FirstSlide />{' '}
                  </SwiperSlide>
                  <SwiperSlide>
                    {' '}
                    <SecondSlide />{' '}
                  </SwiperSlide>
                </Swiper>
              </div>
              <div
                className={styles.swiperPagination}
                onMouseEnter={() => toggleMainSwiper(false)}
                onMouseLeave={() => toggleMainSwiper(true)}
              >
                <Bullet index={0} />
                <Bullet index={1} />
              </div>
              <img
                className={styles.globeImg}
                src={require('../../../assets/web/About/introduction_globe@2x.webp')}
                alt="robot"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default About;
