import styles from './style.module.scss';
import { useTranslation } from 'react-i18next';
import React, { FC, useState } from 'react';
import classNames from 'classnames';
import BonusLeftImage from '@assets/web/bonus/bonus-common_bg_image.webp';

import ic_salary_selected from '@assets/web/bonus/ic_salary_selected@2x.webp';
import ic_leaves_selected from '@assets/web/bonus/ic_leaves_selected@2x.webp';
import dic_meals_selecte from '@assets/web/bonus/ic_meals_selected@2x.webp';
import ic_transportation_selected from '@assets/web/bonus/ic_transportation_selected@2x.webp';
import ic_holiday_selected from '@assets/web/bonus/ic_holiday_selected@2x.webp';
import ic_teambuilding_selected from '@assets/web/bonus/ic_teambuilding_selected@2x.webp';

const preImgList = [
  ic_salary_selected,
  ic_leaves_selected,
  dic_meals_selecte,
  ic_transportation_selected,
  ic_holiday_selected,
  ic_teambuilding_selected,
];

interface Props {}

const Bonus: FC<Props> = () => {
  const { t, i18n } = useTranslation();
  const [activeItem, setActiveItem] = useState('');

  const renderTitle = () => {
    switch (i18n.language) {
      case 'en':
        return (
          <>
            <img
              className={styles.bonusTitleEN}
              src={require('../../../assets/web/bonus/salary_title_EN@2x.webp')}
            />
          </>
        );
      case 'zh':
        return (
          <>
            <img
              className={styles.bonusTitle}
              src={require('../../../assets/web/bonus/salary_title_Chinese@2x.webp')}
            />
            <div className={styles.bonusSubtitle}>salary / benefits</div>
          </>
        );
      case 'thai':
        return (
          <>
            <img
              className={styles.bonusTitleTHAI}
              src={require('../../../assets/web/bonus/salary_title_Thai@2x.webp')}
            />
          </>
        );
    }
  };

  return (
    <div className={styles.bonusWrapper} id="bonus">
      <div className={styles.bonusCont}>
        <div className={styles.bonusLeft}>
          <div className={styles.titleCont}>{renderTitle()}</div>
          <img src={BonusLeftImage} className={styles.bonusLeftImage} />
          <div className={styles.bonusDesc}>{t('salaryDetail')}</div>
        </div>
        <div className={styles.bonusRight}>
          {/* TO DO: Refactor and map */}
          <div className={styles.topRow}>
            {/* SALARY */}
            <div
              className={classNames(styles.bonusItem, {
                [styles.active]: activeItem === 'item1',
              })}
              onClick={() => {
                setActiveItem('item1');
              }}
            >
              <div className={styles.bonusIcon} />
              <div className={styles.bonusText}>
                <div className={styles.bonusType}>{t('Salary1')}</div>
                <ul className={styles.ul}>
                  <li className={styles.li}>{t('Salary_1')}</li>
                  <li className={styles.li}>{t('Salary_2')}</li>
                </ul>
              </div>
            </div>
            {/* LEAVES */}
            <div
              className={classNames(styles.bonusItem, {
                [styles.active]: activeItem === 'item2',
              })}
              onClick={() => {
                setActiveItem('item2');
              }}
            >
              <div className={styles.bonusIcon} />
              <div className={styles.bonusText}>
                <div className={styles.bonusType}>{t('benefits')}</div>
                <ul className={styles.ul}>
                  <li className={styles.li}>{t('benefits_1')}</li>
                </ul>
              </div>
            </div>
            {/* MEALS */}
            <div
              className={classNames(styles.bonusItem, {
                [styles.active]: activeItem === 'item3',
              })}
              onClick={() => {
                setActiveItem('item3');
              }}
            >
              {/*图片预加载*/}
              <div style={{ display: 'none' }}>
                {preImgList.map((item: string, index: number) => {
                  return <img src={item} key={index} />;
                })}
              </div>
              <div className={styles.bonusIcon} />
              <div className={styles.bonusText}>
                <div className={styles.bonusType}>{t('meals')}</div>
                <ul className={styles.ul}>
                  <li className={styles.li}>{t('meals_1')}</li>
                  {i18n.language === 'zh' && (
                    <li className={styles.li}>{t('meals_2')}</li>
                  )}
                </ul>
              </div>
            </div>
          </div>
          <div className={styles.bottomRow}>
            {/* TRANSPORTATION */}
            <div
              className={classNames(styles.bonusItem, {
                [styles.active]: activeItem === 'item4',
              })}
              onClick={() => {
                setActiveItem('item4');
              }}
            >
              <div className={styles.bonusIcon} />
              <div className={styles.bonusText}>
                <div className={styles.bonusType}>{t('Transportation')}</div>
                <ul className={styles.ul}>
                  <li className={styles.li}>{t('Transportation_1')} </li>
                </ul>
              </div>
            </div>
            {/* HOLIDAY */}
            <div
              className={classNames(styles.bonusItem, {
                [styles.active]: activeItem === 'item5',
              })}
              onClick={() => {
                setActiveItem('item5');
              }}
            >
              <div className={styles.bonusIcon} />
              <div className={styles.bonusText}>
                <div className={styles.bonusType}>{t('LeaveEntitlement')}</div>
                <ul className={styles.ul}>
                  <li className={styles.li}>{t('LeaveEntitlement_1')}</li>
                  <li className={styles.li}>{t('LeaveEntitlement_2')}</li>
                  <li className={styles.li}>{t('LeaveEntitlement_3')}</li>
                  <li className={styles.li}>{t('LeaveEntitlement_4')}</li>
                </ul>
              </div>
            </div>
            {/* COMPANY ACTIVITIES */}
            <div
              className={classNames(styles.bonusItem, {
                [styles.active]: activeItem === 'item6',
              })}
              onClick={() => {
                setActiveItem('item6');
              }}
            >
              <div className={styles.bonusIcon} />
              <div className={styles.bonusText}>
                <div className={styles.bonusType}>{t('TeamBuilding')}</div>
                <ul className={styles.ul}>
                  <li className={styles.li}>{t('TeamBuilding_1')} </li>
                  <li className={styles.li}>{t('TeamBuilding_2')}</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Bonus;
