export const JOBLIST = [
  {
    id: 1,
    name: '公关经理',
    headCount: 3,
    category: 'pr',
    time: '2024/12/04',
    detail: {
      responsibility: [
        '负责集团公关项目管理工作，拓展并维护客户关系，为客户提供专业而高效的公关咨询服务',
        '根据客户需求，制定客户公关服务方案，监督跟进各个环节的工作分工和实施。',
        '主导外部客户接洽工作，协助新客户的洽谈，谈判策略及规划，协调及跟进等工作；',
        '开拓与维护核心资源，监控业务舆情，发掘潜在危机并进行拦截处理',
      ],
      requirement: [
        '大学本科以上学历，新闻专业、市场营销专业、公关专业等相关专业；',
        '高情商人才，具备大厂公司公关工作经历优先，精通公关领域的运行逻辑较强的人际理解能力；',
        '中英（泰）文流利，出色的沟通技巧和团队协作精神、书面口头表达能力优秀；',
        '具备战略思维、政策洞察力和项目管理能力；',
        '性格开朗不失沉稳，面对挫折，有较强自我管理能力',
        '泰国籍在中国清华北大复旦等名校留学并回泰国工作，或在泰国朱拉隆功大学留学并长期在泰国工作的中国人（最好是从事房产销售、中介、旅游等）皆可优先安排)',
      ],
      salary: '面谈',
    },
  },
  {
    id: 2,
    name: '精英招聘',
    category: 'sales',
    headCount: 3,
    time: '2024/12/04',
    detail: {
      responsibility: [
        '制定和执行公司的招聘战略，能够流利沟通中英文，确保招聘活动与公司战略一致。',
        '设计、优化招聘流程，确保高效率、高质量的招聘活动。',
        '确保招聘渠道的多样性，包括招聘网站、社交媒体、校园招聘等，以吸引不同层次和领域的人才。',
        '建立并维护与候选人、员工和外部招聘资源的积极关系，提高公司在市场上的声誉。',
        '进行招聘数据整理和分析，制定招聘报告。',
        '协助面试沟通和跟进过程，协助制定和执行候选人评估方案。',
        '确保新员工的良好入职体验，协助公司培养和保留优秀人才。',
      ],
      requirement: [
        '非中国大陆籍，中英文听说读写流利',
        '本科及以上学历，人力资源相关专业优先',
        '至少3年以上招聘经验或猎头经验，有名企背景优先',
        '出色的人际沟通和团队协作能力，能够应对复杂的招聘挑战',
        '具备猎头背景者优先，在菲律宾/泰国/澳门有招聘资源或渠道更佳',
      ],
      salary: '面谈',
    },
  },
  {
    id: 3,
    name: '人事经理',
    headCount: 3,
    category: 'hr',
    time: '2024/12/04',
    detail: {
      responsibility: [
        '制定人力资源策略和目标，与高层管理团队合作，确保人力资源策略与公司目标相一致。',
        '管理招聘和录用流程，制定招聘计划，吸引并招募合适的人才。',
        '管理员工关系，确保公司内部的劳动关系和谐稳定，处理员工投诉和纠纷。',
        '开展员工培训和发展计划，确定培训需求，组织和监督培训活动，提升员工的技能和能力。',
        '管理绩效评估制度，确保绩效评估程序的公正性和准确性，提供有关绩效管理的建议和支持。',
        '管理员工福利计划，包括薪酬福利、健康保险、退休计划等，确保员工福利政策的合规性和吸引力。',
        '制定和实施人力资源政策和程序，确保符合劳动法规和公司政策，并进行定期审查和更新。',
        '协助员工发展和晋升，提供职业规划建议和支持，管理继任计划和人才储备。',
        '管理员工离职流程，包括解雇、辞职和退休等，确保合规性和顺利进行。',
        '维护人力资源记录和文件，包括员工档案、合同、薪酬记录等，保持数据的准确性和机密性。',
      ],
      requirement: [
        '中英文流利；',
        '年龄：38岁以下',
        '熟悉人事所有模块，COE方向；',
        '有海外工作经验加分',
      ],
      salary: '面谈',
    },
  },
];

export const ENJOBLIST = [
  {
    id: 1,
    name: 'Public Relations Manager',
    headCount: 3,
    category: 'pr',
    time: '2024/12/04',
    detail: {
      responsibility: [
        "Responsible for the Group's public relations project management work, expand and maintain customer relations, to provide customers with professional and efficient public relations consulting services",
        'According to customer demand, develop customer PR service program, supervise and follow up the work of all aspects of the division of labor and implementation.',
        'Leading external customer contact work, assisting in new customer negotiation, negotiation strategy and planning, coordination and follow-up work;',
        'Develop and maintain core resources, monitor business public opinion, discover potential crisis and intercept and deal with them.',
      ],
      requirement: [
        'bachelor degree or above, majoring in journalism, marketing, PR and other related majors;',
        'High emotional intelligence talent, with large companies PR work experience is preferred, proficient in the field of public relations operation logic strong interpersonal understanding;',
        'Fluent in Chinese and English (Thai), excellent communication skills and teamwork spirit, excellent written and verbal skills;',
        'Strategic thinking, policy insight and project management skills;',
        'Open-minded and calm personality, strong self-management ability in the face of frustration.',
        'Thai nationals who have studied at Tsinghua University, Fudan University and other prestigious universities in China and returned to work in Thailand, or Chinese who have studied at Chulalongkorn University and worked in Thailand for a long period of time (preferably in the field of real estate sales, brokerage, tourism, etc.) will be given priority.',
      ],
      salary: 'Open',
    },
  },
  {
    id: 2,
    name: 'Elite Recruitment',
    headCount: 3,
    category: 'sales',
    time: '2024/12/04',
    detail: {
      responsibility: [
        "Develop and implement the company's recruitment strategy, be able to communicate fluently in English and Chinese to ensure that recruitment activities are aligned with the company's strategy.",
        'Design and optimize the recruitment process to ensure efficient and high quality recruitment activities.',
        'Ensure the diversity of recruitment channels, including job boards, social media, campus recruiting, etc., to attract talents at different levels and fields.',
        "build and maintain positive relationships with candidates, employees and external recruitment resources to enhance the company's reputation in the market.",
        'Conduct recruitment data compilation and analysis, and develop recruitment reports.',
        'assist in the interview communication and follow-up process, and assist in the development and implementation of candidate assessment programs.',
        'Ensure a positive onboarding experience for new hires and assist in the development and retention of top talent for the company.',
      ],
      requirement: [
        'Non-Chinese nationality, fluent in Chinese and English.',
        'Bachelor degree or above, preferably in human resources related majors.',
        'At least 3 years of recruiting experience or headhunting experience, preferably from a famous company.',
        'excellent interpersonal communication and teamwork skills, able to cope with complex recruitment challenges.',
        'Headhunting background is preferred, and recruitment resources or channels in the Philippines/Thailand/Macau are a plus.',
      ],
      salary: 'Open',
    },
  },
  {
    id: 3,
    name: 'HR Manager',
    headCount: 3,
    category: 'hr',
    time: '2024/12/04',
    detail: {
      responsibility: [
        'Develop HR strategies and objectives and work with the senior management team to ensure that HR strategies are aligned with company objectives.',
        'Manage the recruitment and hiring process, formulate recruitment plans, attract and recruit suitable talents.',
        'Manage employee relations, ensure harmonious and stable labor relations within the company, and handle employee complaints and disputes.',
        "Conduct employee training and development programs, identify training needs, organize and supervise training activities, and enhance employees' skills and abilities.",
        'Manage the performance appraisal system, ensure the fairness and accuracy of the performance appraisal process, and provide advice and support on performance management.',
        'Manage employee benefit programs, including compensation and benefits, health insurance, retirement plans, etc., and ensure the compliance and attractiveness of employee benefit policies.',
        'Formulate and implement human resource policies and procedures to ensure compliance with labor regulations and company policies, and conduct regular reviews and updates.',
        'Assist in employee development and promotion, provide career planning advice and support, and manage succession planning and talent pool.',
        'Manage the employee separation process, including termination, resignation and retirement, to ensure compliance and smooth operation.',
        'Maintains HR records and documents including employee files, contracts, payroll records, etc., keeping data accurate and confidential.',
      ],
      requirement: [
        'Fluent in Chinese and English;',
        'Age: below 38 years old',
        'Familiar with all modules of personnel, COE direction;',
        'Overseas working experience is a plus.',
      ],
      salary: 'Open',
    },
  },
];

export const THAIJOBLIST = [
  {
    id: 1,
    name: 'ผู้จัดการฝ่ายประชาสัมพันธ์',
    headCount: 3,
    category: 'pr',
    time: '2024/12/04',
    detail: {
      responsibility: [
        'รับผิดชอบงานบริหารโครงการประชาสัมพันธ์ของกลุ่มบริษัท ขยายและรักษาความสัมพันธ์กับลูกค้า ให้บริการที่ปรึกษาประชาสัมพันธ์อย่างมืออาชีพและมีประสิทธิภาพแก่ลูกค้า',
        'พัฒนาโปรแกรมบริการประชาสัมพันธ์ลูกค้าตามความต้องการของลูกค้า ดูแลและติดตามงานในทุกด้านของการแบ่งงานและการดำเนินการ',
        'เป็นผู้นำงานติดต่อลูกค้าภายนอก ช่วยเหลือในการเจรจาต่อรองลูกค้าใหม่ วางแผนกลยุทธ์และการเจรจา ประสานงานและติดตามงาน',
        'พัฒนาและรักษาทรัพยากรหลัก ติดตามความคิดเห็นของสาธารณชนในธุรกิจ ค้นหาและสกัดกั้นวิกฤตที่อาจเกิดขึ้นและจัดการกับวิกฤตเหล่านั้น',
      ],
      requirement: [
        'ปริญญาตรีขึ้นไป สาขาการสื่อสารมวลชน การตลาด ประชาสัมพันธ์ และสาขาที่เกี่ยวข้องอื่นๆ',
        'มีความสามารถทางด้านอารมณ์และสติปัญญาสูง มีประสบการณ์ทำงานด้านประชาสัมพันธ์ในบริษัทใหญ่ เชี่ยวชาญด้านตรรกะการดำเนินงานด้านประชาสัมพันธ์ มีความเข้าใจระหว่างบุคคลเป็นอย่างดี',
        'พูดภาษาจีนและภาษาอังกฤษ (ไทย) ได้คล่อง มีทักษะการสื่อสารและจิตวิญญาณแห่งการทำงานเป็นทีมที่ดีเยี่ยม มีทักษะการเขียนและการพูดที่ดีเยี่ยม',
        'มีความคิดเชิงกลยุทธ์ ทักษะเชิงลึกด้านนโยบายและการจัดการโครงการ',
        'มีจิตใจเปิดกว้างและบุคลิกที่สงบ มีความสามารถในการจัดการตนเองที่ดีเมื่อเผชิญกับความหงุดหงิด',
        'คนไทยที่เคยเรียนที่มหาวิทยาลัยชิงหัว มหาวิทยาลัยฟู่ตั้น และมหาวิทยาลัยที่มีชื่อเสียงอื่นๆ ในประเทศจีน และกลับมาทำงานในประเทศไทย หรือชาวจีนที่เคยเรียนที่จุฬาลงกรณ์มหาวิทยาลัย และทำงานในประเทศไทยเป็นเวลานาน (โดยเฉพาะในด้านการขายอสังหาริมทรัพย์ นายหน้า การท่องเที่ยว เป็นต้น) จะได้รับการพิจารณาเป็นลำดับแรก.',
      ],
      salary: 'ตามตกลง',
    },
  },
  {
    id: 2,
    name: 'Elite Recruitment',
    headCount: 3,
    category: 'sales',
    time: '2024/12/04',
    detail: {
      responsibility: [
        'พัฒนาและนำกลยุทธ์การสรรหาบุคลากรของบริษัทไปปฏิบัติ สามารถสื่อสารภาษาอังกฤษและภาษาจีนได้อย่างคล่องแคล่ว เพื่อให้แน่ใจว่ากิจกรรมการสรรหาบุคลากรสอดคล้องกับกลยุทธ์ของบริษัท',
        'ออกแบบและเพิ่มประสิทธิภาพกระบวนการสรรหาบุคลากรเพื่อให้แน่ใจว่ากิจกรรมการสรรหาบุคลากรมีประสิทธิภาพและมีคุณภาพสูง',
        'รับรองความหลากหลายของช่องทางการสรรหาบุคลากร รวมถึงกระดานงาน โซเชียลมีเดีย การสรรหาบุคลากรในมหาวิทยาลัย ฯลฯ เพื่อดึงดูดผู้มีความสามารถในระดับและสาขาต่างๆ',
        'สร้างและรักษาความสัมพันธ์ที่ดีกับผู้สมัคร พนักงาน และแหล่งข้อมูลการสรรหาบุคลากรภายนอก เพื่อเพิ่มชื่อเสียงของบริษัทในตลาด',
        'ดำเนินการรวบรวมและวิเคราะห์ข้อมูลการสรรหาบุคลากร และพัฒนารายงานการสรรหาบุคลากร',
        'ช่วยเหลือในการสื่อสารระหว่างการสัมภาษณ์และกระบวนการติดตามผล และช่วยเหลือในการพัฒนาและนำโปรแกรมการประเมินผู้สมัครไปปฏิบัติ',
        'รับรองประสบการณ์การปฐมนิเทศเชิงบวกสำหรับพนักงานใหม่ และช่วยในการพัฒนาและรักษาบุคลากรที่มีพรสวรรค์สูงไว้สำหรับบริษัท',
      ],
      requirement: [
        'สัญชาติที่ไม่ใช่คนจีน พูดภาษาจีนและภาษาอังกฤษได้คล่อง',
        'สำเร็จการศึกษาระดับปริญญาตรีขึ้นไป โดยเฉพาะอย่างยิ่งในสาขาที่เกี่ยวข้องกับทรัพยากรบุคคล',
        'มีประสบการณ์ในการสรรหาบุคลากรหรือประสบการณ์ในการหาหัวหน้าอย่างน้อย 3 ปี โดยเฉพาะอย่างยิ่งจากบริษัทที่มีชื่อเสียง',
        'มีทักษะการสื่อสารระหว่างบุคคลและการทำงานเป็นทีมที่ยอดเยี่ยม สามารถรับมือกับความท้าทายในการสรรหาบุคลากรที่ซับซ้อนได้',
        'มีประสบการณ์ในการหาหัวหน้าหน่วยงานเป็นที่ต้องการ และทรัพยากรหรือช่องทางการสรรหาบุคลากรในฟิลิปปินส์/ไทย/มาเก๊าจะเป็นข้อได้เปรียบ.',
      ],
      salary: 'ตามตกลง',
    },
  },
  {
    id: 3,
    name: 'ผู้จัดการฝ่ายทรัพยากรบุคคล',
    headCount: 3,
    category: 'hr',
    time: '2024/12/04',
    detail: {
      responsibility: [
        'พัฒนาแผนงานและกลยุทธ์ด้านทรัพยากรบุคคลและทำงานร่วมกับทีมผู้บริหารระดับสูงเพื่อให้แน่ใจว่ากลยุทธ์ด้านทรัพยากรบุคคลสอดคล้องกับวัตถุประสงค์ของบริษัท',
        'จัดการกระบวนการสรรหาและจ้างงาน กำหนดแผนการสรรหา ดึงดูดและคัดเลือกบุคลากรที่มีความสามารถที่เหมาะสม',
        'จัดการความสัมพันธ์ของพนักงาน ให้แน่ใจว่าความสัมพันธ์ด้านแรงงานภายในบริษัทมีความกลมกลืนและมั่นคง และจัดการกับข้อร้องเรียนและข้อพิพาทของพนักงาน',
        'ดำเนินโครงการฝึกอบรมและพัฒนาพนักงาน ระบุความต้องการการฝึกอบรม จัดระเบียบและดูแลกิจกรรมการฝึกอบรม และเพิ่มพูนทักษะและความสามารถของพนักงาน',
        'จัดการระบบการประเมินผลการปฏิบัติงาน ให้แน่ใจว่ากระบวนการประเมินผลการปฏิบัติงานมีความยุติธรรมและถูกต้อง และให้คำแนะนำและสนับสนุนในการจัดการประสิทธิภาพการทำงาน',
        'จัดการโครงการสวัสดิการพนักงาน รวมถึงค่าตอบแทนและสวัสดิการ ประกันสุขภาพ แผนเกษียณอายุ ฯลฯ และให้แน่ใจว่านโยบายสวัสดิการพนักงานมีความสอดคล้องและน่าดึงดูด',
        'กำหนดและนำนโยบายและขั้นตอนด้านทรัพยากรบุคคลไปปฏิบัติเพื่อให้แน่ใจว่าเป็นไปตามข้อบังคับด้านแรงงานและนโยบายของบริษัท และดำเนินการตรวจสอบและปรับปรุงเป็นประจำ ',
        'ช่วยเหลือในการพัฒนาและเลื่อนตำแหน่งพนักงาน ให้คำแนะนำและการสนับสนุนด้านการวางแผนอาชีพ และจัดการการวางแผนการสืบทอดตำแหน่งและกลุ่มบุคลากรที่มีความสามารถ',
        'จัดการกระบวนการเลิกจ้างพนักงาน รวมถึงการเลิกจ้าง การลาออก และการเกษียณอายุ เพื่อให้แน่ใจว่าเป็นไปตามข้อกำหนดและการดำเนินงานราบรื่น',
        'ดูแลบันทึกและเอกสารด้านทรัพยากรบุคคล รวมถึงไฟล์พนักงาน สัญญา บันทึกการจ่ายเงินเดือน ฯลฯ โดยรักษาข้อมูลให้ถูกต้องและเป็นความลับ',
      ],
      requirement: [
        'พูดภาษาจีนและภาษาอังกฤษได้คล่อง',
        'อายุ: ต่ำกว่า 38 ปี',
        'คุ้นเคยกับโมดูลทั้งหมดของบุคลากร ทิศทาง COE',
        'ประสบการณ์ทำงานในต่างประเทศถือเป็นข้อดี',
      ],
      salary: 'ตามตกลง',
    },
  },
];
