import React, { useEffect, useState } from 'react';
import { Route, Routes } from 'react-router-dom';
import { isMobile as deviceMobile } from 'react-device-detect';
import NotFoundPage from './page/web/maintenance/404';
import MobilePages from './routes/MobilePages';
import WebPages from './routes/WebPages';
import MobileSearch from 'components/mobile/opportunity/search';
import styles from './App.module.scss';

const App: React.FC = () => {
  const [isMobile, setIsMobile] = useState<boolean>(deviceMobile);

  useEffect(() => {
    const checkIsMobile = () =>
      setIsMobile(window.matchMedia('(max-width: 768px)').matches);

    // 初始化检测
    checkIsMobile();

    // 监听窗口变化
    const mediaQuery = window.matchMedia('(max-width: 768px)');
    mediaQuery.addEventListener('change', checkIsMobile);

    // 清理事件监听
    return () => mediaQuery.removeEventListener('change', checkIsMobile);
  }, []);

  // 根据设备类型选择页面组件
  const CurrentPages = isMobile ? MobilePages : WebPages;

  return (
    <div className={styles.App}>
      <Routes>
        <Route path="/" element={<CurrentPages />} />
        <Route path="/search" element={<MobileSearch />} />
        <Route path="*" element={<NotFoundPage />} />
      </Routes>
    </div>
  );
};

export default App;
